/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

const serverConfig =
  typeof __SERVER__ !== 'undefined' &&
  __SERVER__ &&
  !process.env.DISABLE_USER_INFO_PROXY
    ? {
        expressMiddleware: [
          require('./express-middleware/userInfoProxy').default(),
        ],
      }
    : { expressMiddleware: [] };

const externalUserInfoTarget =
  process.env.RAZZLE_EXTERNAL_USER_INFO_TARGET ||
  (__CLIENT__ && window?.env?.RAZZLE_EXTERNAL_USER_INFO_TARGET) ||
  `https://lignanoservizi.soluzionipauat.it/portal`;

export default function applyConfig(config) {
  config.settings.siteProperties.externalUserInfoTarget =
    externalUserInfoTarget;
  config.settings.siteProperties.arLoginUrl = `${externalUserInfoTarget}/autenticazione`;
  config.settings.expressMiddleware = [
    ...(config.settings.expressMiddleware || []),
    ...serverConfig.expressMiddleware,
  ];
  config.settings.serverConfig = {
    ...config.settings.serverConfig,
    expressMiddleware: [
      ...(config.settings.serverConfig?.expressMiddleware || []),
      ...serverConfig.expressMiddleware,
    ],
  };

  return config;
}
