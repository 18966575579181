import { GET_USER_INFO } from '../constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  data: null,
  error: null,
};

export function userInfo(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_USER_INFO}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_USER_INFO}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null,
      };
    case `${GET_USER_INFO}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}
