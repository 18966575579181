import { GET_USER_INFO } from '../constants/ActionTypes';

export function getUserInfo() {
  return {
    type: GET_USER_INFO,
    request: {
      op: 'get',
      path: `${window.location.origin}/user_logged`,
    },
  };
}
